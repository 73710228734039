let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Amir Martinez",
    "role": "QA Automation Engineer and junior Frontend Developer",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I am a Results-focused, persistent QA Analyst and Software Developer with 5+ years of experience identifying evolving marketplace needs to understand client impact and leverage cross-functional relations for solution implementation and functional requirement development.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/amir-martinez-058717100",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/at0mzk1",
          "className":"fa fa-github"
        },
        {
          "name":"twitter",
          "url":"http://twitter.com/amirmartinez",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I am a Systems and Computing Engineering major from Dominican Republic. I would qualify myself as a solutions-oriented team player seeking to leverage an Agile Practice engagement background into a QA Engineer, System Analyst or Software Developer role with a progressive organization.",
    "address":"Dominican Republic",
    "website":"https://at0mz.com",
    "education":[
      {
        "UniversityName":"Pontificia Universidad Catolica Madre y Maestra",
        "specialization":"Systems and Computing Engineering",
        "MonthOfPassing":"Sep",
        "YearOfPassing":"2016"
      }
    ],
    "work":[
      {
        "CompanyName":"Verizon Enterprise Solutions",
        "specialization":"QA Analyst and Team Lead",
        "MonthOfLeaving":"Dec",
        "YearOfLeaving":"2016",
        "Achievements":"3 times Employee of the Month"
      },
      {
        "CompanyName":"mGage - A Vivial Company",
        "specialization":"QA Automation Engineer",
        "MonthOfLeaving":"Current",
        "Achievements":"Employee of the month, August 2017"
      }
    ],
    "skillsDescription":"My whole professional career is based around QA, on both manual and automated tests but as a hobby i'm learning front end development based in ReactJS",
    "skills":[
      {
        "skillname":"Selenium"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"Reactjs"
      },
      {
        "skillname":"CSS"
      }
    ],
    "portfolio":[
      {
        "name":"Fortnite Friends Tracker",
        "description":"This is an application made so that you could easily create a dashboard tracking your friends stats.",
        "imgurl":"images/portfolio/tracker.png"
      },
      {
        "name":"Agencia Ogando",
        "description":"Company webpage to promote customs service.",
        "imgurl":"images/portfolio/agency.png"
      },
      {
        "name":"Meme Generator",
        "description":"Custom website to generate memes.",  
        "imgurl":"images/portfolio/memegen.png"
      },
      {
        "name":"Mu Legend DB",
        "description":"Database webpage for Mu Legend, an Isometric medieval fantasy MMORPG",
        "imgurl":"images/portfolio/mulegend.png"
      }
    ]
  }
  
  export default resumeData